import { render, staticRenderFns } from "./hero-marquee.vue?vue&type=template&id=391bc92c&scoped=true&lang=pug&"
import script from "./hero-marquee.vue?vue&type=script&lang=coffee&"
export * from "./hero-marquee.vue?vue&type=script&lang=coffee&"
import style0 from "./hero-marquee.vue?vue&type=style&index=0&id=391bc92c&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "391bc92c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Wysiwyg: require('/opt/build/repo/node_modules/@bkwld/cloak/components/wysiwyg.coffee').default,BlocksBtnList: require('/opt/build/repo/nuxt-app/components/blocks/btn-list.vue').default,KlaviyoForm: require('/opt/build/repo/nuxt-app/components/globals/klaviyo-form.vue').default,OlioNuovoLines: require('/opt/build/repo/library/components/globals/olio-nuovo-lines.vue').default,ResponsiveCraftVisual: require('/opt/build/repo/node_modules/@bkwld/cloak/components/responsive-craft-visual.vue').default,CraftVisual: require('/opt/build/repo/node_modules/@bkwld/cloak/components/craft-visual.coffee').default})
