












export default

	props:
		image: Object
		index: Number
		page: Number

	computed:
		classes: -> [
			'active' if @index == @page
		]

	methods:
		updateCarousel: (e) ->
			@$emit 'updateCarousel', @index

