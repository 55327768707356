import { render, staticRenderFns } from "./cart-product.vue?vue&type=template&id=7d99641c&scoped=true&lang=pug&"
import script from "./cart-product.vue?vue&type=script&lang=coffee&"
export * from "./cart-product.vue?vue&type=script&lang=coffee&"
import style0 from "./cart-product.vue?vue&type=style&index=0&id=7d99641c&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7d99641c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Btn: require('/opt/build/repo/nuxt-app/components/globals/btn/btn.vue').default,AccessibilityBtn: require('/opt/build/repo/library/components/globals/accessibility-btn.vue').default,ShopifyVisual: require('/opt/build/repo/library/components/globals/shopify-visual.coffee').default,ProductPrice: require('/opt/build/repo/nuxt-app/components/globals/product/price.vue').default})
