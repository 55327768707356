




















import { isDark } from '@bkwld/light-or-dark'

export default

	data: ->
		config:
			loop: true
			autoplayDelay: 6
			slidesPerPage: 1
			showDots: false
			showArrows: true
			gutter: 'var(--gutter)'

	computed:
		banners: -> @$store?.state?.globals?.banners || []

