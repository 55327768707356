






















export default

	computed:
		links: -> @$store.state.globals.footerContent?.links
		copyright: -> @$store.state.globals.footerContent?.copyright
		social: -> @$store.state.globals.socialMedia
		facebook: -> @social?.facebook
		instagram: -> @social?.instagram
		pinterest: -> @social?.pinterest

