import { render, staticRenderFns } from "./product-image-slide.vue?vue&type=template&id=9ae94628&scoped=true&lang=pug&"
import script from "./product-image-slide.vue?vue&type=script&lang=coffee&"
export * from "./product-image-slide.vue?vue&type=script&lang=coffee&"
import style0 from "./product-image-slide.vue?vue&type=style&index=0&id=9ae94628&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9ae94628",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProductVisual: require('/opt/build/repo/library/components/globals/product-visual.coffee').default})
