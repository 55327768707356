


















# Mapping of Craft _typename to component
export mapping =
	blocks_wrapper_BlockType: 'blocks-wrapper'
	blocks_wrapper5050_BlockType: 'blocks-wrapper-5050'
	blocks_accordionWrapper_BlockType: 'accordion-wrapper'
	blocks_copy_BlockType: 'blocks-copy'
	blocks_faqList_BlockType: 'blocks-faq-list'
	blocks_cardCarousel_BlockType: 'blocks-card-carousel'
	blocks_staggeredCardCarousel_BlockType: 'blocks-staggered-card-carousel'
	blocks_toutCarousel_BlockType: 'blocks-tout-carousel'
	blocks_videoCarousel_BlockType: 'blocks-video-carousel'
	blocks_cardGrid_BlockType: 'blocks-card-grid'
	blocks_productCarousel_BlockType: 'blocks-product-carousel'
	blocks_mediaAsset_BlockType: 'blocks-media-asset'
	blocks_simpleMarquee_BlockType: 'blocks-simple-marquee'
	blocks_halfMarquee_BlockType: 'blocks-half-marquee'
	blocks_heroMarquee_BlockType: 'blocks-hero-marquee'
	blocks_videoMarquee_BlockType: 'blocks-video-marquee'
	blocks_buttons_BlockType: 'blocks-buttons'
	blocks_spacer_BlockType: 'blocks-spacer'
	blocks_storeLocator_BlockType: 'blocks-store-locator'
	blocks_pressKit_BlockType: 'blocks-press-kit'
	blocks_form_BlockType: 'blocks-form-block'
	blocks_wrapper_BlockType: 'blocks-wrapper'
	blocks_articleListing_BlockType: 'blocks-article-listing'
	blocks_recipeListing_BlockType: 'blocks-recipe-listing'
	blocks_productGrid_BlockType: 'blocks-product-grid'
	blocks_iconGrid_BlockType: 'blocks-icon-grid'
	blocks_splitFeature_BlockType: 'blocks-split-feature'
	blocks_html_BlockType: 'blocks-html'
	blocks_newsListingGrid_BlockType: 'blocks-news-listing-grid'
	blocks_smsSignup_BlockType: 'blocks-sms-signup'
	blocks_newsletter_BlockType: 'blocks-newsletter'
	blocks_instagram_BlockType: 'blocks-instagram'
	blocks_logoGarden_BlockType: 'blocks-logo-garden'

# Hard import marquee components so they don't get chunked
import '~/components/blocks/half-marquee'
import '~/components/blocks/hero-marquee'
import '~/components/blocks/simple-marquee'
import '~/components/blocks/video-marquee'

# Component def for this block
export default
	name: 'BlockList'

	# Povide list of blocks if needed by children (hero marquee color sampling)
	provide: ->
		blocks: @createdBlocks

	components: {
		...mapping
	}

	props:
		blocks: Array
		block: Object # Used by reusable sections

		# Used by Wrapper so the children don't get an offset of zero and disable
		# lazly loading. Note, this isn't perfect because any blocks after the
		# wrapper block won't currently be offset by the preceeding wrapper's
		# children count, but it works for our simple need of enabling lazy loading.
		indexOffset:
			type: Number
			default: 0

	computed:

		# Get the list of blocks from multiple props or default to an empty array
		blocksSource: -> @block?.reusableSection?[0]?.blocks || @blocks || []

		# Filter the blocks to those that have been defined.  To fix errors in dev
		# environments after content model is created but commits with the new
		# block component have not been pulled down.
		createdBlocks: ->
			@blocksSource.map (block) -> {
				...block
				componentName: switch block.__typename
					when 'blocks_reusableSection_BlockType' then 'blocks-list'
					else mapping[block.__typename]
			}
			.filter (block) -> !!block.componentName

	# methods:
	# 	_createdBlocks: -> @createdBlocks

