
















































import DetachableHeader from 'vue-detachable-header'
import 'vue-detachable-header/index.css'
import dimensions from 'library/assets/vars/dimensions'
import AccountHeader from '~/components/pages/customer/account-header'
import SiteLogo from './site-logo'
import CartIcon from './cart-icon'
import DesktopNav from './nav/desktop/nav'
import HeaderDropdown from './header-dropdown'
import SearchDropdown from './search-dropdown'
import ClickOutside from 'vue-click-outside'
import Banner from 'library/components/layout/header/site-banner'
import trapFocusMixin from 'library/mixins/trap-focus-mixin'
import transparentHeaderMixin from 'library/mixins/transparent-header-mixin'

export default

	components: {
		DetachableHeader
		AccountHeader
		SiteLogo
		CartIcon
		DesktopNav
		HeaderDropdown
		SearchDropdown
		Banner
	}

	directives: {
		ClickOutside
	}

	mixins: [trapFocusMixin, transparentHeaderMixin]

	data: -> searchOpen: false

	computed:
		height: -> @headerH + @bannerH
		headerH: -> parseInt dimensions['header-h']
		bannerH: -> parseInt dimensions['banner-h']

	methods:
		openCart: ->
			@closeAllDropdowns()
			@$store.commit('cart/open')

		toggleSearch: ->
			return @closeSearch() if @searchOpen
			@closeAllDropdowns()
			@searchOpen = true
			@$store.commit('ui/setMenuOpen', true)
			@setTrap(@$refs.searchTrap)

		closeSearch: ->
			return if !@searchOpen
			@searchOpen = false
			@$store.commit('ui/setMenuOpen', false)
			@releaseAllTraps()

		clickOutside: ->
			@closeAllDropdowns()

		closeAllDropdowns: -> @closeSearch()

		onMouseLeave: ->
			if !@$store?.state?.ui?.menuOpen
				@setHovered false

	watch:
		$route: ->
			@closeAllDropdowns()
			@searchOpen = false
			@setHovered false
			@releaseAllTraps()

		'$store.state.ui.menuOpen': (open) ->
			if !open then @setHovered false


