














export default

	props:
		gallery: Array
		page: Number

	methods:
		updateCarousel: (index) ->
			@$emit 'updateCarousel', index

