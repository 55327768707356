###
Shared for implementing transparent headers
###
export default

	data: ->
		hovered: false
		scrollY: 0
		transparent: false

	mounted: ->
		@setHeaderTransparency()
		@handleScroll()
		window.addEventListener('scroll', @handleScroll)

	destroyed: ->
		window.removeEventListener('scroll', @handleScroll)

	computed:
		transparentHeaderPages: -> @$store?.state?.globals?.header?.transparentHeaderPages
		isMenuOpen: ->  @$store?.state?.header?.menuOpen
		isTransparent: -> @transparent && !@hovered && !@pastThreshold && !@isMenuOpen
		pastThreshold: -> @scrollY > 300
		classes: -> [
			'transparent' if @isTransparent
		]

	methods:
		setHovered: (val) -> @hovered = val
		handleScroll: (e) ->
			return if !@transparent
			@scrollY = window.scrollY

		setHeaderTransparency: ->
			match = @transparentHeaderPages.find (item) =>
				@$route.path == item.url

			if match then @transparent = true
			else @transparent = false

	watch:
		$route: ->
			@setHeaderTransparency()
