

















import MainNavItem from './main-nav-item'
import trapFocusMixin from 'library/mixins/trap-focus-mixin'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import { wait } from '@bkwld/cloak/services/helpers'

export default

	mixins: [ trapFocusMixin ]

	components: { MainNavItem }

	mounted: ->
		@initNavLock()

	computed:
		nav: -> @$store.state.globals.mobileNavigation
		navigationItems: -> @nav?.items || []

	methods:
		initNavLock: ->
			clearAllBodyScrollLocks()
			wait 100,
				@scrollAndTrapMainNav()

		scrollAndTrapMainNav: ->
			disableBodyScroll @$refs.scroller
			@setTrap(@$refs.scroller, '0')

