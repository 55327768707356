


















export default

	props: transparent: Boolean

	data: ->
		menuOpen: false
		inverted: false

	computed:
		classes: -> [
			'menu-open' if @menuOpen
			'inverted' if @inverted
		]

		expandedLabel: ->
			if @menuOpen then return 'true'
			else return 'false'

	methods:
		onClick: -> @$emit('click')

		toggle: (open) ->
			if open then @menuOpen = true
			else @menuOpen = false

	watch:
		transparent: (val) -> @inverted = val

