






















export default
	name: 'DefaultLayout'

	computed:
		# When menu is open, add aria-hidden to main.
		# Prevent SR from reading the page behind the open menu.
		mainHidden: -> if @$store.state.ui.mobileMenuOpen then 'true' else null

