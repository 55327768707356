import { render, staticRenderFns } from "./variant-carousel.vue?vue&type=template&id=4e03e27b&scoped=true&lang=pug&"
import script from "./variant-carousel.vue?vue&type=script&lang=coffee&"
export * from "./variant-carousel.vue?vue&type=script&lang=coffee&"
import style0 from "./variant-carousel.vue?vue&type=style&index=0&id=4e03e27b&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e03e27b",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PdpProductImageSlide: require('/opt/build/repo/nuxt-app/components/pages/pdp/product-image-slide.vue').default,SsrCarousel: require('/opt/build/repo/node_modules/vue-ssr-carousel/index.js').default,PdpThumbnailNavigation: require('/opt/build/repo/nuxt-app/components/pages/pdp/thumbnail-navigation.vue').default})
