{ whenConsentChanges, ONETRUST_CATEGORIES } = require 'library/services/onetrust/await-consent'

syncOneTrustToShopifyPrivacy = ->

	privacyApi = window.Shopify.customerPrivacy

	# Watch for the OneTrust consent to change and update Shopify privacy API
	whenConsentChanges (enabledConsentIds) ->

		# Get current consent as booleans
		shopifyConsent = privacyApi.currentVisitorConsent()
		shopifyConsent.analytics = consentStrToBool shopifyConsent.analytics
		shopifyConsent.marketing = consentStrToBool shopifyConsent.marketing

		# Get the proposed new consent
		newConsent =
			analytics: enabledConsentIds.includes ONETRUST_CATEGORIES.PERFORMANCE
			marketing: enabledConsentIds.includes ONETRUST_CATEGORIES.TARGETING
			preferences: false,
			headlessStorefront: true,
			checkoutRootDomain: "shop.californiaoliveranch.com",
			storefrontRootDomain: "shop.californiaoliveranch.com",
			storefrontAccessToken: process.env.SHOPIFY_STOREFRONT_TOKEN

		# Update the consent if there were changes
		if shopifyConsent.analytics isnt newConsent.analytics or shopifyConsent.marketing isnt newConsent.marketing
			privacyApi.setTrackingConsent newConsent, (error) ->
				console.error 'setTrackingConsent', error if error

# Convert Shopify's consent strings to boolean values for easier comparison
consentStrToBool = (value) ->
	return true if value == 'yes'
	return false if value == 'no'
	null # Will be an empty string if not set

# Run the function immediately when the plugin loads
setTimeout(syncOneTrustToShopifyPrivacy, 1000)
