





































export default

	props: block: Object

	computed:
		showButtons: -> !!@block?.buttons?.length

