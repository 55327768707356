# Customize the injected Recharge link
# https://docs.rechargepayments.com/docs/translate-the-customer-account-access-link#customize-link-added-with-javascript

window.Shopify ?= {}  # Ensure window.Shopify exists without overwriting
window.Shopify.shop ?= "cor-#{process.env.APP_ENV}.myshopify.com"  # Only set if not already defined

window.rcLoginAccessLink =

	# Reduce "Need help" to a simple link
	translation: en:
		titleText: ''
		linkText: 'View Subscriptions'

	# Add to login page after "Forgot Password"
	settings: [
		{
			page: '/account/login'
			injectionAnchorSelectors: ['.account-btn-wrap']
			injectionPosition: 'beforeend'
		}

	]
