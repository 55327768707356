import Cookies from 'js-cookie'
import { wait } from '@bkwld/cloak/services/helpers'
import { ONETRUST_COOKIE_NAME } from './onetrust-constants'

# Check if the OneTrust SDK has loaded and it's set it's cookie
export getOneTrust = -> new Promise (resolve) ->
	checkLoad = ->
		if !!window?.OneTrust && Cookies.get ONETRUST_COOKIE_NAME
		then resolve window.OneTrust
		else wait 50, checkLoad
	checkLoad()
