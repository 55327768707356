








import logo from 'library/assets/images/logo.svg'
import logoInverted from 'library/assets/images/logo-inverted.svg'

export default

	props: transparent: Boolean

	data: ->
		inverted: false

	mounted: -> @inverted = @transparent

	computed:
		logo: ->
			if @inverted then return logoInverted
			else return logo

	watch:
		transparent: (val) -> @inverted = val


